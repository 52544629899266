<template>
  <div class="text-center ma-1">
    <v-img
      width="64"
      @error="() => (logoSrcError = true)"
      v-if="!logoSrcError"
      :src="getLogo"
      class="mx-auto"
    ></v-img>
    <v-icon size="64" class="ma-0" v-if="logoSrcError">{{
      getNameInit
    }}</v-icon>
    <span v-if="showName">{{ store.name }}</span>
  </div>
</template>

<script>
export default {
  name: "StoreLogo",
  props: {
    store: Object,
    showName: Boolean,
  },
  data() {
    return {
      logoSrcError: false,
    };
  },
  computed: {
    getLogo() {
      return `${this.getApiBaseUrl}/files/image?path=stores/logos/_${this.store.uuid}.png`;
    },
    getName() {
      return this.store ? this.store.name : null;
    },
    getNameInit() {
      if (!this.store.name) {
        return "mdi-circle";
      }

      return `mdi-alpha-${this.store.name
        .substring(0, 1)
        .toLowerCase()}-circle`;
    },
  },
};
</script>
